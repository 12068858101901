import { GenUUidByType } from '@/utils/UCore.js'
export default {
  methods: {
    /**
     * 组件上移
     * @param svgLists
     * @param select_svg
     */
    moveUp(svgLists, select_svg) {
      svgLists[select_svg.Index].svgPositionY -= 1
      this.$eventBus.$emit('updataSelectB')
    },
    /**
     * 组件下移
     * @param svgLists
     * @param select_svg
     */
    moveDown(svgLists, select_svg) {
      svgLists[select_svg.Index].svgPositionY += 1
      this.$eventBus.$emit('updataSelectB')
    },
    /**
     * 组件左移
     * @param svgLists
     * @param select_svg
     */
    moveLeft(svgLists, select_svg) {
      svgLists[select_svg.Index].svgPositionX -= 1
      this.$eventBus.$emit('updataSelectB')
    },
    /**
     * 组件右移
     * @param svgLists
     * @param select_svg
     */
    moveRight(svgLists, select_svg) {
      svgLists[select_svg.Index].svgPositionX += 1
      this.$eventBus.$emit('updataSelectB')
    },
    /**
     * 组件复制
     * @param svgLists
     * @param select_svg
     */
    hotkeyCopy(svgLists, select_svg, uuid) {
      if(this.containArr.length>0){
        let newArr=[]
        this.containArr.forEach(item => {
          console.log(item)
          let uid=GenUUidByType(item.id.slice(0, 2), 8, 16)
          let info= this.copyitem2(svgLists,item,uid)
          newArr.push(info)
        });  
        this.containArr=newArr   
      }else{
        this.copyitem(svgLists,select_svg,uuid)
      }
    },
    copyitem2(svgLists,select_svg,uuid){
      let svginfo={} 
      if (select_svg.extend_attr.color_id) {
        //渐变颜色id
          svginfo = {
          ...JSON.parse(JSON.stringify(select_svg)),
          id: uuid,
          svgPositionX: select_svg.svgPositionX + 10,
          svgPositionY: select_svg.svgPositionY + 10,
          title: select_svg.title
        }
        svginfo.extend_attr.color_id.val = 'cid' + uuid
        svgLists.push(svginfo)
      } else {
        svginfo ={
          ...JSON.parse(JSON.stringify(select_svg)),
          id: uuid,
          svgPositionX: select_svg.svgPositionX + 10,
          svgPositionY: select_svg.svgPositionY + 10,
          title: select_svg.title
        }
        svgLists.push(svginfo)
      }
      return svginfo

    },
    copyitem(svgLists,select_svg,uuid){
      if (svgLists[select_svg.Index].extend_attr.color_id) {
        //渐变颜色id
        let svginfo = {
          ...JSON.parse(JSON.stringify(svgLists[select_svg.Index])),
          id: uuid,
          svgPositionX: svgLists[select_svg.Index].svgPositionX + 10,
          svgPositionY: svgLists[select_svg.Index].svgPositionY + 10,
          title: svgLists[select_svg.Index].title
        }
        svginfo.extend_attr.color_id.val = 'cid' + uuid
        svgLists.push(svginfo)
      } else {
        svgLists.push({
          ...JSON.parse(JSON.stringify(svgLists[select_svg.Index])),
          id: uuid,
          svgPositionX: svgLists[select_svg.Index].svgPositionX + 10,
          svgPositionY: svgLists[select_svg.Index].svgPositionY + 10,
          title: svgLists[select_svg.Index].title
        })
      }

    },
    /**
     * 组件删除
     * @param svgLists
     * @param select_svg
     */
    hotkeyDel(svgLists, select_svg) {
      if(this.containArr.length>0){
        const arr1 = this.containArr
        const res = svgLists.filter(item1 => !arr1.some(item2 => item2.id === item1.id))     
        this.svgLists=res       
        this.containArr=[]
      }else{
      svgLists.splice(select_svg.Index, 1)
      this.$eventBus.$emit('drawChange', [svgLists, ''])
      }
    },
    /**
     * 组件置于顶层
     * @param svgLists
     * @param select_svg
     */
    hotkeyPutOnTop(svgLists, select_svg) {
      const temp = svgLists[select_svg.Index]
      svgLists.splice(select_svg.Index, 1)
      svgLists.push(temp)
      select_svg.Index = svgLists.length - 1
      this.$eventBus.$emit('drawChange', [svgLists, select_svg])
    },
    /**
     * 组件置于底层
     * @param svgLists
     * @param select_svg
     */
    hotkeyPutOnButtom(svgLists, select_svg) {
      const temp = svgLists[select_svg.Index]
      svgLists.splice(select_svg.Index, 1)
      svgLists.unshift(temp)
      select_svg.Index = 0
      this.$eventBus.$emit('drawChange', [svgLists, select_svg])
    },
    /**
     * 组件上移一层
     * @param svgLists
     * @param select_svg
     */
    hotkeyPutOnUp(svgLists, select_svg) {
      if (svgLists.length === 1 || select_svg.Index === svgLists.length - 1) {
        return
      }
      const temp = svgLists[select_svg.Index]

      // svgLists[select_svg.Index] = svgLists[select_svg.Index + 1]
      // svgLists[select_svg.Index + 1] = temp
      this.$set(svgLists, select_svg.Index, svgLists[select_svg.Index + 1])
      this.$set(svgLists, select_svg.Index + 1, temp)
      select_svg.Index += 1

      this.$eventBus.$emit('drawChange', [svgLists, select_svg])
    },
    /**
     * 组件下移一层
     * @param svgLists
     * @param select_svg
     */
    hotkeyPutOnDown(svgLists, select_svg) {
      if (svgLists.length === 1 || select_svg.Index === 0) {
        return
      }
      const temp = svgLists[select_svg.Index]
      // svgLists[select_svg.Index] = svgLists[select_svg.Index - 1]
      // svgLists[select_svg.Index - 1] = temp
      this.$set(svgLists, select_svg.Index, svgLists[select_svg.Index - 1])
      this.$set(svgLists, select_svg.Index - 1, temp)
      select_svg.Index -= 1
      this.$eventBus.$emit('drawChange', [svgLists, select_svg])
    }
  }
}
