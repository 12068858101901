<template>
  <div>
    <div
      id="svg-wrap"
      ref="canvas"
      @mousedown="MousedownCanvas"
      @mousemove="MouseMove"
      @mouseup="MouseupCanvas"
      @contextmenu.stop="contextmenuEvent"
    >
      <!--拖动辅助线-->
      <div
        id="guide-x"
        ref="guidex_dom"
        :style="'border-top: ' + 1 / scaleValue + 'px dashed #55f;pointer-events: none'"
      ></div>
      <div
        id="guide-y"
        ref="guidey_dom"
        :style="'border-left: ' + 1 / scaleValue + 'px dashed #55f;pointer-events: none'"
      ></div>
      <svg
        id="svgMain"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        :style="'background:' + showBgColor"
        :width="box.width * stationObj.zoom + 'px'"
        :height="box.height * stationObj.zoom + 'px'"
      >
        <defs>
          <component :is="'style'">
            /* 这里的style预览、导出用，有这个style管道才会流动，所以不写在css里 */ /*
            组态管线正向流动效果 */ .hoverClass:hover{ filter: drop-shadow(0px 0px 6px
            rgba(25,144,255,1));}.svg_ani_flow { stroke-dasharray: 1000; stroke-dashoffset:
            1000;box-shadow: 0px 0px 5px #2680eb; animation: ani_flow 10s linear infinite;
            animation-fill-mode: forwards; -webkit-animation: ani_flow 10s linear infinite;
            -webkit-animation-fill-mode: forwards; } @keyframes ani_flow { from { stroke-dasharray:
            10, 5; } to { stroke-dasharray: 13, 5; } } @-webkit-keyframes ani_flow { from {
            stroke-dasharray: 10, 5; } to { stroke-dasharray: 13, 5; } } /* 组态管线停止流动效果 */
            .svg_ani_flow_stop { stroke-dasharray: 1000; stroke-dashoffset: 1000; animation:
            ani_flow_stop 10s linear infinite; animation-fill-mode: forwards; -webkit-animation:
            ani_flow_stop 10s linear infinite; -webkit-animation-fill-mode: forwards; } @keyframes
            ani_flow_stop { from { stroke-dasharray: 10, 5; } to { stroke-dasharray: 10, 5; } }
            @-webkit-keyframes ani_flow_stop { from { stroke-dasharray: 10, 5; } to {
            stroke-dasharray: 10, 5; } } /* 组态管线反向流动效果 */ .svg_ani_flow_back {
            stroke-dasharray: 1000; stroke-dashoffset: 1000; animation: ani_flow_back 10s linear
            infinite; animation-fill-mode: forwards; -webkit-animation: ani_flow_back 10s linear
            infinite; -webkit-animation-fill-mode: forwards; } @keyframes ani_flow_back { from {
            stroke-dasharray: 13, 5; } to { stroke-dasharray: 10, 5; } } @-webkit-keyframes
            ani_flow_stop { from { stroke-dasharray: 10, 5; } to { stroke-dasharray: 10, 5; } }
          </component>
        </defs>
        <filter id="solid" x="0" y="0" width="1" height="1">
          <feFlood flood-color="rgb(255,255,255)" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter id="textbackground" x="-0.05" y="-0.1" width="1.1" height="1.2">
          <feFlood flood-color="#2E2E2E" />
          <feComposite in="SourceGraphic" />
        </filter>
        <g
          id="svgCanvas"
          x="0"
          y="0"
          width="1920"
          height="1920"
          :transform="'translate(0,0)' + 'rotate(0,0,0)' + 'scale(' + stationObj.zoom + ')'"
        >
          <rect
            id="selectBoxall"
            style="fill:rgb(0,0,255);fill-opacity:0.1;stroke-width:1;stroke:rgb(149,149,149);stroke-dasharray:5 5;"
            stroke-width="1"
            :width="selectBoxall.w"
            :height="selectBoxall.h"
            :x="selectBoxall.x"
            :y="selectBoxall.y"
          ></rect>
          <template v-for="(item, index) in svgLists">
            <g
              v-if="checkPoint(item.title)"
              :id="item.id"
              :key="item.id"
              style="cursor:pointer"
              :title="item.title"
              :class="
                currentInfo($parent.selectSvgInfo.id, item.id, item.type, containArr)
                  ? 'topo-layer-view-selected hoverClass'
                  : 'hoverClass'
              "
              :transform="
                'translate(' +
                  item.svgPositionX +
                  ',' +
                  item.svgPositionY +
                  ')' +
                  'rotate(' +
                  (item.angle || 0) +
                  ',' +
                  (item.extend_attr && item.extend_attr.width ? setCenterA(item, 'x') : 0) +
                  ',' +
                  (item.extend_attr && item.extend_attr.height ? setCenterA(item, 'y') : 0) +
                  ')' +
                  'scale(' +
                  item.sizeW +
                  ',' +
                  item.sizeH +
                  ')'
              "
              @mousedown="
                MousedownSvg(
                  item.id,
                  index,
                  item.svgPositionX,
                  item.svgPositionY,
                  $event,
                  item.extend_attr
                )
              "
            >
              <SvgComponents
                :component_prop="item"
                :svgInfoData="svgInfoData"
                :box="box"
                :pointNameChecked="pointNameChecked"
                :pointChecked="pointChecked"
              ></SvgComponents>
            </g>
          </template>
        </g>
        <g
          id="selectorGroup"
          :transform="'translate(0,0)' + 'rotate(0,0,0)' + 'scale(' + stationObj.zoom + ')'"
        ></g>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          :x="stationObj.legendx * stationObj.zoom"
          :y="stationObj.legendy * stationObj.zoom"
        >
          <g
            v-for="(item, index) in legend"
            :id="item.id + '.legend'"
            :key="item.id + '.legend'"
            :transform="
              'translate(' +
                (8 + item.x) +
                ',' +
                (index * 30 + item.y)* stationObj.zoom +
                ')' +
                'rotate(0)' +
                'scale(' +
                item.size +
                ')'
            "
          >
            <Svglegend :component_prop="item" :svgInfoData="svgInfoData"></Svglegend>
          </g>
          <g v-for="(item, index) in legend" :id="item.id + '.legend1'" :key="item.id + '.legend1'">
            <text
              :x="35"
              :y="index * 30 + 20"
              class="text--pattern"
              font-size="12"
              :fill="box.background == 'fff' ? '#000000' : '#ffffff'"
            >
              {{ item.title }}
            </text>
          </g>
          <rect
            id="legend"
            fill="#7AD6CA"
            x="1"
            y="1"
            fill-opacity="0"
            width="108"
            :height="legend.length * 30"
            stroke="#ccc"
          ></rect>
        </svg>
      </svg>
    </div>

    <!-- 右键菜单 -->
    <ul v-show="display_contextmenu" ref="contextMenuRef" class="contextMenu">
      <li v-for="(item, index) in contextmenu_data" :key="index" @click="contextmenuSelect(item)">
        <p :class="item.enable ? '' : 'disabled'">
          {{ item.name }}
          <span class="shortcut">{{ item.hotkey }}</span>
        </p>
      </li>
    </ul>
    <!-- <div class="Svglegend">
      <li v-for="(item,index) in legend" :key="index">
                <img :src="item.priview_img" draggable="draggable" />
                <div class="title">{{ item.title }}</div>
      </li>
    </div> -->
  </div>
</template>
<script>
import { GenUUidByType } from '@/utils/UCore.js'
import SvgComponents from './SvgComponents.vue'
import Svglegend from './Svglegend.vue'

import editSize from '../svgTool/editSize'
import HotkeyFunc from '../svgTool/HotkeyFunc'
import scadaApi from "@/apis/scadaApi";
export default {
  components: { SvgComponents, Svglegend },
  mixins: [editSize, HotkeyFunc],
  props: ['svgInfoData', 'selectSvgInfo', 'box', 'toolTypeArray', 'bgColor'],
  data() {
    return {
      showBgColor: '#fff',
      clickPointChecked: 1, // 1:显示采集点，2:单击设备可见性
      pointChecked: true,
      pointNameChecked: true, //是否显示名称，这个版本可能要删掉了
      pointArr: [
        '总累标况',
        '瞬时标况',
        '压力采集',
        '浓度采集',
        '温度采集',
        '总累工况',
        '瞬时工况',
        '采集点'
      ],
      showSvg: true,
      containArr: [],
      selectBoxall: { x: 0, y: 0, w: 0, h: 0 },
      keydown: true,
      svgLists: [],
      legend: [],
      selectSvg: {},
      clickType: '', //鼠标点击行为
      scaleValue: 1, //缩放倍数
      mouseInfo: {},
      stationObj: {
        zoom: 1,
        legendx: 10,
        legendy: 10
      },
      display_contextmenu: false, //右键弹窗
      contextmenu_data: [], //右键菜单数据列表
      history: {
        alone: true,
        allow: true,
        index: 0,
        list: []
      },
      psInfo: false,
      infoChange: false,
      mouseUpstate: true,
      drageState: true,
      drawLine: [],
      lineObj: {},
      mouseNum: 0
    }
  },
  provide: {},
  watch: {
    containArr(val) {
      let data = true
      if (val.length > 0) {
        data = false
        this.$parent.$refs.topToolBar.alignDisable = false
      } else {
        this.$parent.$refs.topToolBar.alignDisable = true
      }
      //this.$eventBus.$emit('selectChange', data)
    },
    svgLists: {
      handler(newval, oldval) {
        if (this.mouseUpstate) {
          this.changeList()
        }
        this.infoChange = true
        if (this.svgLists && this.svgLists.length > 0) {
          let groupData = this.groupBy(this.svgLists, link => {
            return link.type
          })
          //  this.legend=groupData
          let info = this.svgInfoData
          let newlegend = []
          for (let i = 0; i < info.length; i++) {
            for (let key in groupData) {
              if (key == `"${info[i].type}"`) {
                if (
                  info[i].type != 'textbox' &&
                  info[i].type != 'module' &&
                  info[i].type != 'skid' &&
                  info[i].type != 'collectPoint' &&
                  info[i].data_type != 'collectionPoint' &&
                  info[i].type != 'customize' &&
                  info[i].create_type == 'draggable'
                ) {
                  let extend_attr = info[i].extend_attr
                  let size = 1
                  let x = 0
                  let y = 5
                  if (extend_attr.width > extend_attr.height) {
                    size = 10 / extend_attr.width
                    y = (30 - size * extend_attr.height * 2) / 2
                  } else {
                    size = 10 / extend_attr.height
                    x = 10 - size * extend_attr.width
                  }
                  let sub = {
                    id: info[i].type,
                    title: info[i].title,
                    type: info[i].type,
                    extend_attr: extend_attr,
                    size: size,
                    priview_img: info[i].priview_img,
                    x: x,
                    y: y
                  }
                  newlegend.push(sub)
                }
              }
            }
          }
          this.legend = newlegend
        } else {
          this.legend = []
        }
      },
      // 代表在wacth里声明了firstName这个方法之后立即先去执行handler方法
      //   immediate: true,
      deep: true
    },

    'box.showImg'(val) {
      this.showImg = val
    },
    bgColor(val) {
      this.showBgColor = val
    }
  },
  created() {},
  beforeDestroy() {
    localStorage.setItem('svgListsZL', JSON.stringify(this.svgLists))
  },
  destroyed() {
    window.onbeforeunload = null
  },
  mounted() {
    this.initSvg()
    let that = this
    //页面刷新
    window.onbeforeunload = function(e) {
      localStorage.setItem('svgListsZL', JSON.stringify(that.svgLists))
      return ''
    }
    let svgLists = localStorage.getItem('svgListsZL')
    if (svgLists != 'undefined') {
      this.svgLists = JSON.parse(svgLists) || []
    } else {
      this.svgLists = []
    }
    
    document.onkeyup = function(e) {
      that.keydown = true
    }
    document.onkeydown = function(e) {
      if (that.keydown && e.key == 'Control') {
        e.preventDefault()
        that.keydown = false
      }

      if (e.ctrlKey && e.key == 'z') {
        e.preventDefault()
        if (that.history.index > 1) {
          that.history.allow = false

          that.history.index = that.history.index - 1
          that.svgLists = JSON.parse(JSON.stringify(that.history.list[that.history.index - 1]))
          that.$parent.selectSvgInfo = {}
          that.selectSvg = {}
          that.deleteEit()
          that.$eventBus.$emit('drawChange', [that.$parent.svgLists, that.selectSvg])
        }
      } else if (e.ctrlKey && e.key == 'y') {
        e.preventDefault()

        if (that.history.index < that.history.list.length) {
          that.history.allow = false
          that.history.index = that.history.index + 1
          that.svgLists = JSON.parse(JSON.stringify(that.history.list[that.history.index - 1]))
          that.deleteEit()
          that.$parent.selectSvgInfo = {}
          that.selectSvg = {}
          that.$eventBus.$emit('drawChange', [that.$parent.svgLists, that.selectSvg])
        }

        //  that.$parent.selectSvgInfo = {}
      } else if (e.ctrlKey && e.key == 's') {
        e.preventDefault()
        that.$parent.$refs.topToolBar.saveFile()
      }

      //如果没选中组件
      if (!that.selectSvg.ID) {
        if (e.ctrlKey && e.key.toLowerCase() == 'c') {
          e.preventDefault()
          that.hotkeyCopy(that.svgLists, that.selectSvg, 0)
        } else if (e.key == 'Delete') {
          e.preventDefault()
          that.hotkeyDel(that.svgLists, that.selectSvg)
        }
        return
      }
      if (!e.ctrlKey && e.key == 'ArrowUp') {
        e.preventDefault()
        that.moveUp(that.svgLists, that.selectSvg)
      } else if (!e.ctrlKey && e.key == 'ArrowDown') {
        e.preventDefault()
        that.moveDown(that.svgLists, that.selectSvg)
      } else if (!e.ctrlKey && e.key == 'ArrowLeft') {
        e.preventDefault()
        that.moveLeft(that.svgLists, that.selectSvg)
      } else if (!e.ctrlKey && e.key == 'ArrowRight') {
        e.preventDefault()
        that.moveRight(that.svgLists, that.selectSvg)
      }
      //ctrl  c
      else if (e.ctrlKey && e.key.toLowerCase() == 'c') {
        e.preventDefault()
        let uuid = GenUUidByType(that.selectSvg.ID.slice(0, 2), 8, 16)
        that.hotkeyCopy(that.svgLists, that.selectSvg, uuid)
      }
      //deleted
      else if (e.key == 'Delete') {
        e.preventDefault()
        that.hotkeyDel(that.svgLists, that.selectSvg)
        that.$parent.selectSvgInfo = {}
        that.deleteEit()
      }
      //上移一层
      else if (e.ctrlKey && e.key == 'ArrowUp') {
        e.preventDefault()
        that.hotkeyPutOnUp(that.svgLists, that.selectSvg)
      }
      //下移一层
      else if (e.ctrlKey && e.key == 'ArrowDown') {
        e.preventDefault()
        that.hotkeyPutOnDown(that.svgLists, that.selectSvg)
      }
      //置于底层
      else if (e.ctrlKey && e.key == 'ArrowLeft') {
        e.preventDefault()
        that.hotkeyPutOnButtom(that.svgLists, that.selectSvg)
      }
      //置于顶层
      else if (e.ctrlKey && e.key == 'ArrowRight') {
        e.preventDefault()
        that.hotkeyPutOnTop(that.svgLists, that.selectSvg)
      }
    }
  },
  methods: {
    // 判断数据的类型
    checkPoint(title = '') {
      let that = this
      if (that.pointChecked) {
        return true
      } else {
        if (that.pointArr.includes(title)) {
          return false
        } else {
          return true
        }
      }
    },
    groupBy(list, fn) {
      const groups = {}
      list.forEach(function(o) {
        const group = JSON.stringify(fn(o))
        groups[group] = groups[group] || []
        groups[group].push(o)
      })
      // return Object.keys(groups).map(function (group) {
      //     return groups[group];
      // });
      return groups
    },
    currentInfo(sid, id, type, containArr) {
      if (!sid) {
        let state = false
        for (let i = 0; i < containArr.length; i++) {
          if (containArr[i].id == id) {
            state = true
            return true
          }
        }
        return state
      }
      let haves = this.toolTypeArray.indexOf(type)
      if (sid == id && haves != -1) {
        return true
      } else {
        return false
      }
    },
    contextmenuEvent(e) {
      e.preventDefault()
      if (this.drawLine.length > 0) {
        this.drawLine = []
        return
      }
      this.display_contextmenu = true
      this.$refs.contextMenuRef.style.left = e.offsetX + 'px'
      this.$refs.contextMenuRef.style.top = e.offsetY + 'px'
      this.contextmenu_data = [
        { type: 'copy', name: '复制', hotkey: 'Ctrl+C', enable: true },
        { type: 'delete', name: '删除', hotkey: 'Delete', enable: true },
        { type: 'putOnTop', name: '置于顶层', hotkey: 'Ctrl+→', enable: true },
        { type: 'putOnButtom', name: '置于底层', hotkey: 'Ctrl+←', enable: true },
        { type: 'putOnUp', name: '上移一层', hotkey: 'Ctrl+↑', enable: true },
        { type: 'putOnDown', name: '下移一层', hotkey: 'Ctrl+↓', enable: true }
      ]

      if (this.selectSvg.ID) {
        //判断当前选中组件的index
        if (this.svgLists.length === 1) {
          //禁用下移
          this.contextmenu_data[3].enable = false
          this.contextmenu_data[5].enable = false
          //禁用上移
          this.contextmenu_data[2].enable = false
          this.contextmenu_data[4].enable = false
        } else if (this.selectSvg.Index === 0) {
          //禁用下移
          this.contextmenu_data[3].enable = false
          this.contextmenu_data[5].enable = false
        } else if (this.selectSvg.Index === this.svgLists.length - 1) {
          //禁用上移
          this.contextmenu_data[2].enable = false
          this.contextmenu_data[4].enable = false
        }
      } else {
        this.contextmenu_data.forEach(item => {
          item.enable = false
        })
      }
      if (this.containArr.length > 0) {
        this.contextmenu_data[0].enable = true
        this.contextmenu_data[1].enable = true
      }
    },
    alignMenu(val) {
      let minx = Number.MAX_VALUE,
        maxx = Number.MIN_VALUE,
        miny = Number.MAX_VALUE,
        maxy = Number.MIN_VALUE

      let bboxes = []
      let containArr = this.containArr
      for (let i = 0; i < containArr.length; i++) {
        let doc = document.getElementById(containArr[i].id)
        bboxes[i] = {
          id: containArr[i].id,
          width: doc.getBBox().width,
          height: doc.getBBox().height,
          sx: containArr[i].sizeW,
          sy: containArr[i].sizeH,
          x: doc.transform.baseVal.getItem(0).matrix.e || 0,
          y: doc.transform.baseVal.getItem(0).matrix.f || 0
        }
        if (containArr[i].type == 'pipeline') {
          let pathSs = containArr[i].extend_attr.pathS
          let minxs = Number.MAX_VALUE
          let minys = Number.MAX_VALUE
          pathSs.forEach(item3 => {
            let a = item3.split(' ')
            if (minxs > Number(a[0])) {
              minxs = Number(a[0])
            }
            if (minys > Number(a[1])) {
              minys = Number(a[1])
            }
          })
          bboxes[i].x = minxs + containArr[i].svgPositionX
          bboxes[i].y = minys + containArr[i].svgPositionY
        }

        if (bboxes[i].x < minx) minx = bboxes[i].x
        if (bboxes[i].y < miny) miny = bboxes[i].y
        if (bboxes[i].x + bboxes[i].width * bboxes[i].sx > maxx)
          maxx = bboxes[i].x + bboxes[i].width * bboxes[i].sx
        if (bboxes[i].y + bboxes[i].height * bboxes[i].sy > maxy)
          maxy = bboxes[i].y + bboxes[i].height * bboxes[i].sy
      }

      bboxes.forEach(item => {
        this.svgLists.forEach(item2 => {
          if (item.id == item2.id) {
            if (item2.type == 'pipeline') {
              switch (val) {
                case 'top':
                  item2.svgPositionY = miny - (item.y - item2.svgPositionY)
                  break
                case 'bottom':
                  item2.svgPositionY = maxy - (item.y - item2.svgPositionY) - item.height
                  break
                case 'left':
                  item2.svgPositionX = minx - (item.x - item2.svgPositionX)
                  break
                case 'right':
                  item2.svgPositionX = maxx - (item.x - item2.svgPositionX) - item.width
                  break
                case 'horizontally':
                  item2.svgPositionX = maxx - (item.x - item2.svgPositionX) - item.width / 2
                  break
                case 'vertically':
                  item2.svgPositionY = maxy - (item.y - item2.svgPositionY) - item.height / 2
                  break
              }
            } else {
              switch (val) {
                case 'top':
                  item2.svgPositionY = miny
                  break
                case 'bottom':
                  item2.svgPositionY = maxy - item.height * item.sy
                  break
                case 'left':
                  item2.svgPositionX = minx
                  break
                case 'right':
                  item2.svgPositionX = maxx - item.width * item.sx
                  break
                case 'horizontally':
                  item2.svgPositionX = maxx - (item.width * item.sx) / 2
                  break
                case 'vertically':
                  item2.svgPositionY = maxy - (item.height * item.sy) / 2
                  break
              }
            }
          }
        })
      })
    },
    contextmenuSelect(item) {
      if (!item.enable) {
        return
      }
      switch (item.type) {
        case 'copy':
          let uuid = 0
          if (this.selectSvg.ID) {
            GenUUidByType(this.selectSvg.ID.slice(0, 2), 8, 16)
          }
          this.hotkeyCopy(this.svgLists, this.selectSvg, uuid)
          break
        case 'delete':
          this.hotkeyDel(this.svgLists, this.selectSvg)
          this.$parent.selectSvgInfo = {}
          this.deleteEit()
          break
        case 'putOnTop':
          this.hotkeyPutOnTop(this.svgLists, this.selectSvg)
          break
        case 'putOnButtom':
          this.hotkeyPutOnButtom(this.svgLists, this.selectSvg)
          break
        case 'putOnUp':
          this.hotkeyPutOnUp(this.svgLists, this.selectSvg)
          break
        case 'putOnDown':
          this.hotkeyPutOnDown(this.svgLists, this.selectSvg)
          break
      }
      this.display_contextmenu = false
    },
    setCenterA(item, type) {
      let w = item.extend_attr.width * item.sizeW
      let h = item.extend_attr.height * item.sizeH
      //  .sizeH,item.extend_attr.width,item.extend_attr.height,item.angle
      // let angle=item.angle
      let center = { x: w, y: h }
      let result = center.x
      // if(angle){
      //    const r = Math.sqrt(w * w + h * h)
      //     center.x = r * Math.sin(angle)
      //     center.y = r * Math.cos(angle)
      // }
      if (type == 'x') {
        result = center.x
      } else {
        result = center.y
      }

      return result
    },
    mousewheel(e) {
      if (e.ctrlKey) {
        e.preventDefault()
        if (e.deltaY > 0) {
          this.stationObj.zoom -= 0.1
        } else {
          //放大
          this.stationObj.zoom += 0.1
        }
        if (this.stationObj.zoom < 0.1) {
          this.stationObj.zoom = 0.1
        } else if (this.stationObj.zoom > 100) {
          this.stationObj.zoom = 100
        }
        this.$parent.boxz = this.stationObj.zoom.toFixed(1)
        this.zoomChange(this.stationObj.zoom, true)
        this.$parent.setBoxz(this.stationObj.zoom)
      }
    },
    zoomChange(zoom, type) {
      if (!type) {
        this.stationObj.zoom = zoom
      }
      this.$parent.$refs.ruler.updateRulers(zoom)
      this.initGroup()
      this.updataSelectB()
    },
    MouseMove(e) {
      let _this = this
      let { offsetX, offsetY } = e
      let zoom = this.stationObj.zoom
      let l = this.drawLine.length
      if (l > 1) {
        this.setNewPath(offsetX, offsetY)
        return
      }
      if (this.clickType == 'draggable') {
        if (this.mouseStatus == 0) {
          return
        }
        let svgType = this.svgLists[this.selectSvg.Index].type
        let noAdsorptionArr = [
          // 'pipeline',
          // 'pipeline_v',
          // 'pipeline_h',
          'totalTiredness',
          'conditioning',
          'pressureCollection',
          'concentrationCollection',
          'temperatureCollection',
          'totalTiredConditions',
          'instantaneousService'
        ]

        //绘制常用工具、设备等会有吸附
        if (noAdsorptionArr.indexOf(svgType) == -1) {
          // let svgID = this.svgLists[this.selectSvg.Index].id;
          let svgID = this.selectSvg.ID
          //排除当前元素剩下的所有svg元素的列表
          let anyPositionList = this.svgLists.filter(function(list) {
            return list.id != svgID && list.type.indexOf('pipeline') != -1
          })
          //将要移动的元素坐标设备为鼠标坐标
          let svgPositionX = this.selectSvg.pointX
          let svgPositionY = this.selectSvg.pointY
          svgPositionX += (offsetX - this.selectSvg.mPositionX) / zoom / this.scaleValue
          svgPositionY += (offsetY - this.selectSvg.mPositionY) / zoom / this.scaleValue

          let obj = this.svgLists[this.selectSvg.Index]
          let width = 0
          let height = 0
          if (obj.extend_attr.width) {
            width = obj.extend_attr.width * zoom * obj.sizeW
          } else if (obj.extend_attr.endpoint_x) {
            width =
              (obj.extend_attr.endpoint_x.val - obj.extend_attr.startpoint_x.val) * zoom * obj.sizeW
          }
          if (obj.extend_attr.height) {
            height = obj.extend_attr.height * zoom * obj.sizeH
          } else if (obj.extend_attr.endpoint_y) {
            height =
              (obj.extend_attr.endpoint_y.val - obj.extend_attr.startpoint_y.val) * zoom * obj.sizeW
          }

          // console.log(obj.extend_attr.width, zoom, obj.sizeW)
          // setTimeout(function() {
          //少于十个像素自动吸附
          //从所有的x坐标列表中查与当前坐标少于10个像素的组件是否存在
          // console.log(width,height,svgPositionX,svgPositionY)
          let [exitsAdsorbX, exitsAdsorbXe, exitsAdsorbY, exitsAdsorbYe] = [[], [], [], []]
          for (let i = 0; i < anyPositionList.length; i++) {
            let [x, y, svgW, svgH] = [0, 0, 0, 0]
            if (anyPositionList[i].type == 'pipeline') {
              // console.log('管道',anyPositionList[i])
              let path = anyPositionList[i].extend_attr.pathS
              let parr = path[0].split(' ')
              // 相加偏移量获取真实x
              x = Number(parr[0]) + anyPositionList[i].svgPositionX
              y = Number(parr[1]) + anyPositionList[i].svgPositionY
              let endArr = path[path.length - 1].split(' ')
              svgW = Number(endArr[0]) - Number(parr[0])
              svgH = Number(endArr[1]) - Number(parr[1])
            } else {
              // console.log('横纵',anyPositionList[i])
              x = anyPositionList[i].svgPositionX
              y = anyPositionList[i].svgPositionY
              svgW = anyPositionList[i].extend_attr.endpoint_x
                ? anyPositionList[i].extend_attr.endpoint_x.val
                : 0
              svgH = anyPositionList[i].extend_attr.endpoint_y
                ? anyPositionList[i].extend_attr.endpoint_y.val
                : 0
            }
            if (-10 / zoom < x - (svgPositionX + width) && x - (svgPositionX + width) < 10 / zoom) {
              exitsAdsorbX.push(anyPositionList[i])
            } else if (
              -10 / zoom < x + svgW - (svgPositionX + width) &&
              x + svgW - (svgPositionX + width) < 10 / zoom
            ) {
              exitsAdsorbXe.push(anyPositionList[i])
            } else if (
              -10 / zoom < y - (svgPositionY + height) &&
              y - (svgPositionY + height) < 10 / zoom
            ) {
              exitsAdsorbY.push(anyPositionList[i])
            } else if (
              -10 / zoom < y + svgH - (svgPositionY + height) &&
              y + svgH - (svgPositionY + height) < 10 / zoom
            ) {
              exitsAdsorbYe.push(anyPositionList[i])
            }
          }

          if (exitsAdsorbXe.length > 0) {
            let wx = 0
            if (exitsAdsorbXe[0].type == 'pipeline') {
              //就直接在这赋值了
              let path = exitsAdsorbXe[0].extend_attr.pathS
              let paths = path[path.length - 1]
              let parr = paths.split(' ')
              // 相加偏移量获取真实x
              wx = Number(parr[0]) + exitsAdsorbXe[0].svgPositionX - width
            } else {
              wx = exitsAdsorbXe[0].svgPositionX + exitsAdsorbXe[0].extend_attr.endpoint_x.val
            }
            svgPositionX = wx
            // svgPositionX = exitsAdsorbXe[0].svgPositionX
          }
          if (exitsAdsorbX.length > 0) {
            let wx = 0
            if (exitsAdsorbX[0].type == 'pipeline') {
              //就直接在这赋值了
              let path = exitsAdsorbX[0].extend_attr.pathS[0]
              let parr = path.split(' ')
              // 相加偏移量获取真实x
              wx = Number(parr[0]) + exitsAdsorbX[0].svgPositionX - width
            } else {
              wx = exitsAdsorbX[0].svgPositionX - width
            }
            svgPositionX = wx
          }

          //y轴相同 横向坐标自动吸附
          // let exitsAdsorbY = anyPositionList.filter(function(list) {
          //   return (
          //     -10 / zoom < list.svgPositionY - (svgPositionY + height) &&
          //     list.svgPositionY - (svgPositionY + height) < 10 / zoom
          //   )
          // })
          // let exitsAdsorbYe = anyPositionList.filter(function(list) {
          //   return (
          //     -10 / zoom < list.svgPositionY + height - (svgPositionY + height) &&
          //     list.svgPositionY + height - (svgPositionY + height) < 10 / zoom
          //   )
          // })

          // 根据类型判断
          if (exitsAdsorbYe.length > 0) {
            let wh = 0
            if (exitsAdsorbYe[0].type == 'pipeline') {
              //就直接在这赋值了
              let path = exitsAdsorbYe[0].extend_attr.pathS
              let paths = path[path.length - 1]
              let parr = paths.split(' ')
              // 相加偏移量获取真实x
              wh = Number(parr[1]) + exitsAdsorbYe[0].svgPositionY
            } else {
              wh = exitsAdsorbYe[0].svgPositionY + exitsAdsorbYe[0].extend_attr.endpoint_y.val
            }
            svgPositionY = wh
            // svgPositionY = exitsAdsorbYe[0].svgPositionY
          }
          if (exitsAdsorbY.length > 0) {
            let wh = 0
            if (exitsAdsorbY[0].type == 'pipeline') {
              //就直接在这赋值了
              let path = exitsAdsorbY[0].extend_attr.pathS[0]
              let parr = path.split(' ')
              // 相加偏移量获取真实x
              wh = Number(parr[1]) + exitsAdsorbY[0].svgPositionY - height
            } else {
              wh = exitsAdsorbY[0].svgPositionY - height
            }
            svgPositionY = wh
            // svgPositionY = exitsAdsorbY[0].svgPositionY - height
          }
          let objSvg = _this.svgLists[_this.selectSvg.Index]
          if (
            objSvg.extend_attr &&
            objSvg.extend_attr.Locking &&
            objSvg.extend_attr.Locking.val.selectval
          ) {
            //已锁定的元素不能拖拽
          } else {
            _this.svgLists[_this.selectSvg.Index].svgPositionX = svgPositionX
            _this.svgLists[_this.selectSvg.Index].svgPositionY = svgPositionY
          }

          //从所有的x坐标列表中查当前坐标是否存在
          let exitsNowX = anyPositionList.filter(function(list) {
            let x = 0
            if (list.type == 'pipeline') {
              let path = list.extend_attr.pathS[0]
              let parr = path.split(' ')
              // 相加偏移量获取真实x
              x = Number(parr[0]) + list.svgPositionX
            } else {
              x = list.svgPositionX
            }
            return x === svgPositionX + width || x + width === svgPositionX + width
          })

          if (exitsNowX.length > 0) {
            let w = width
            if (exitsAdsorbXe.length > 0) {
              w = 0
            }
            _this.$refs.guidey_dom.style.setProperty(
              'left',
              (svgPositionX + w) * zoom - 1 / _this.scaleValue + 22 + 'px'
            )
            _this.$refs.guidey_dom.style.display = 'inline'
          } else {
            _this.$refs.guidey_dom.style.display = 'none'
          }
          //从所有的y坐标列表中查当前坐标是否存在
          let exitsNowY = anyPositionList.filter(function(list) {
            let y = 0
            if (list.type == 'pipeline') {
              let path = list.extend_attr.pathS[0]
              let parr = path.split(' ')
              // 相加偏移量获取真实x
              y = Number(parr[1]) + list.svgPositionY
            } else {
              y = list.svgPositionY
            }
            return y === svgPositionY + height || y + height === svgPositionY + height
          })
          if (exitsNowY.length > 0) {
            let h = height
            if (exitsAdsorbYe.length > 0) {
              h = 0
            }
            _this.$refs.guidex_dom.style.setProperty(
              'top',
              (svgPositionY + h) * zoom - 1 / _this.scaleValue + 20 + 'px'
            )
            _this.$refs.guidex_dom.style.display = 'inline'
          } else {
            _this.$refs.guidex_dom.style.display = 'none'
          }
          //编辑框跟着移动
          _this.updataSelectB()
          //}, 1)
        } else {
          //不吸附
          let svgPositionX = this.selectSvg.pointX
          let svgPositionY = this.selectSvg.pointY
          svgPositionX += (offsetX - this.selectSvg.mPositionX) / zoom / this.scaleValue
          svgPositionY += (offsetY - this.selectSvg.mPositionY) / zoom / this.scaleValue
          this.svgLists[this.selectSvg.Index].svgPositionX = svgPositionX
          this.svgLists[this.selectSvg.Index].svgPositionY = svgPositionY
        }
      } else if (this.clickType == 'click') {
        if (this.mouseStatus == 0) {
          return
        }
        this.$parent.selectSvgInfo.mPoint.endX = e.offsetX / zoom
        this.$parent.selectSvgInfo.mPoint.endY = e.offsetY / zoom

        //     //拓展属性里未配置的属性不进行赋值
        // if (this.svgLists[this.selectSvg.index].extend_attr?.startpoint_x?.val != null) {
        //   this.svgLists[this.selectSvg.index].extend_attr.startpoint_x.val = 0;
        // }
        // if (this.svgLists[this.selectSvg.index].extend_attr?.startpoint_y?.val != null) {
        //   this.svgLists[this.selectSvg.index].extend_attr.startpoint_y.val = 0;
        // }

        if (
          this.$parent.selectSvgInfo.extend_attr &&
          this.$parent.selectSvgInfo.extend_attr.endpoint_x
        ) {
          this.$parent.selectSvgInfo.extend_attr.endpoint_x.val =
            (e.offsetX - this.mouseInfo.mPositionX) / zoom
        }
        if (
          this.$parent.selectSvgInfo.extend_attr &&
          this.$parent.selectSvgInfo.extend_attr.endpoint_y
        ) {
          this.$parent.selectSvgInfo.extend_attr.endpoint_y.val =
            (e.offsetY - this.mouseInfo.mPositionY) / zoom
        }
      }

      _this.mouseMoveEvent(e)
    },
    MousedownCanvas(e) {
      let that = this
      // 组和撬的采集点的删除按钮
      let obj = e.srcElement ? e.srcElement : eveent.target
      if (obj.id && obj.id.indexOf('svg_close_bg') != -1) {
        this.$confirm('确定删除该采集点吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            let index = obj.id.split('|')[1]
            that.svgLists.forEach((el, svgIndex) => {
              if (el.id == that.selectSvg.ID) {
                if (el.extend_attr.f_list.length == 1) {
                  //删除最后一个采集点，连带着组、撬一起删
                  that.svgLists.splice(svgIndex, 1)
                } else {
                  let deleInfo = el.extend_attr.f_list[index]
                  let size = el.extend_attr.f_content_size.val
                  let width1 = that.$parent.getActualWidthOfChars(deleInfo.pointName, size, 400)
                  let width2 = that.$parent.getActualWidthOfChars(deleInfo.currVal, size, 700)
                  let width3 = that.$parent.getActualWidthOfChars(deleInfo.dataUnit, size, 400)
                  el.extend_attr.f_list.splice(index, 1)

                  // 删除采集点，重新重新计算矩形宽度
                  if (
                    width1 >= el.extend_attr.f_content_width1 ||
                    width2 >= el.extend_attr.f_content_width2 ||
                    width3 >= el.extend_attr.f_content_width3
                  ) {
                    let widht1Arry = []
                    let widht2Arry = []
                    let widht3Arry = []
                    el.extend_attr.f_list.forEach(co => {
                      widht1Arry.push(that.$parent.getActualWidthOfChars(co.pointName, size, 400))
                      widht2Arry.push(that.$parent.getActualWidthOfChars(co.currVal, size, 700))
                      widht3Arry.push(that.$parent.getActualWidthOfChars(co.dataUnit, size, 400))
                    })
                    let width1 = Math.max(...widht1Arry)
                    let width2 = Math.max(...widht2Arry)
                    let width3 = Math.max(...widht3Arry)
                    el.extend_attr.f_content_width1 = width1
                    el.extend_attr.f_content_width2 = width2
                    el.extend_attr.f_content_width3 = width3
                    let width = (width1 + width2 + width3 + 97) / 2 //采集点矩形框宽度
                    el.extend_attr.width = width
                  }
                  let rowHeight = that.$parent.getActualHeightOfChars('计算高度', size) + 10 //这里的10是两个矩形框间距
                  el.extend_attr.height = el.extend_attr.height - rowHeight / 2
                }
              }
            })
            that.$message.success('删除成功!')
            // 清空选中状态
            that.clearSelectSvg()
            return
          })
          .catch(() => {
            // 清空选中状态
            that.clearSelectSvg()
            return
          })
      }
      this.mouseUpstate = false
      this.infoChange = false
      this.display_contextmenu = false //隐藏右键菜单
      let l = this.drawLine.length
      if (l > 1) {
        if (e.button == 0) {
          let point = e.offsetX / this.stationObj.zoom + ' ' + e.offsetY / this.stationObj.zoom
          //  this.drawLine[l-1]=point
          this.drawLine.push(point)
          this.setNewPath()
          this.xf(l, e.offsetX, e.offsetY)

          if (this.mouseNum > 0) {
            this.drawLine = this.drawLine.slice(0, l)
            this.setNewPath()
            this.drawLine = []
          } else {
            this.mouseNum += 1
          }
          setTimeout(() => {
            this.mouseNum = 0
          }, 350)
        } else {
          this.setNewPath()
        }
        return
      }
      if (this.clickType == 'draggable') {
        return
      }
      const { screenX, screenY } = e
      this.mouseInfo.mPositionX = e.offsetX
      this.mouseInfo.mPositionY = e.offsetY
      if (this.clickType != 'draggable' && this.clickType != 'click' && !this.selectBoxall.drag) {
        this.selectBoxall.state = true
        this.selectBoxall.x = e.offsetX
        this.selectBoxall.y = e.offsetY
        this.containArr = []
      }
      if (
        this.$store.getters.CurrentlySelectedToolBar &&
        this.$store.getters.CurrentlySelectedToolBar.CreateType == 'click'
      ) {
        //根据类型和鼠标位置创建组件
        let svg_id = GenUUidByType(this.$store.getters.CurrentlySelectedToolBar.Identity, 8, 16)
        let extend_attr = this.$store.getters.CurrentlySelectedToolBar.extend_attr
        if (extend_attr && extend_attr.endpoint_y) {
          extend_attr = JSON.parse(JSON.stringify(extend_attr))
          extend_attr.endpoint_y.val = 0
          extend_attr.startpoint_y.val = 0
        } else if (extend_attr && extend_attr.endpoint_x) {
          extend_attr = JSON.parse(JSON.stringify(extend_attr))
          extend_attr.endpoint_x.val = 0
          extend_attr.startpoint_x.val = 0
        }
        let zoom = this.stationObj.zoom
        let svgItem = {
          id: svg_id,
          title: this.$store.getters.CurrentlySelectedToolBar.Title,
          type: this.$store.getters.CurrentlySelectedToolBar.Type,
          typeName: this.$store.getters.CurrentlySelectedToolBar.TypeName,
          //svgColor: this.$store.getters.CurrentlySelectedToolBar.Color,
          extend_attr: extend_attr,
          svgPositionX: e.offsetX / zoom,
          svgPositionY: e.offsetY / zoom,
          mPoint: {
            startX: e.offsetX / zoom,
            startY: e.offsetY / zoom,
            endX: e.offsetX / zoom,
            endY: e.offsetY / zoom
          },
          sizeW: 1,
          sizeH: 1,
          angle: 0
          //translate:`translate(${this.mousePosition.positionX},${this.mousePosition.positionY})`
        }

        if (svgItem.type == 'pipeline') {
          let p = svgItem.svgPositionX + ' ' + svgItem.svgPositionY
          // svgItem.extend_attr.path='M'+p

          if (l < 1) {
            let arr = [p, p]
            this.drawLine = arr
            svgItem.extend_attr.path = 'M' + p
          }
          svgItem.svgPositionX = 0
          svgItem.svgPositionY = 0
          this.lineObj = svgItem
        }
        this.svgLists.push(svgItem)
        this.$parent.selectSvgInfo = svgItem
        if (this.$parent.selectSvgInfo.extend_attr.color_id) {
          //渐变颜色id
          this.$parent.selectSvgInfo.extend_attr.color_id.val = 'cid' + svg_id
        }
        this.mouseStatus = 1
        this.clickType = 'click'
        this.selectSvg.ID = svg_id
        this.selectSvg.Index = this.svgLists.length - 1
      } else if (e.target.id == 'svgMain') {
        //点击画布，清空选中状态
        this.$eventBus.$emit('drawChange', [this.$parent.svgLists, this.selectSvg])
        this.deleteEit()
        this.drageState = false
        this.$parent.selectSvgInfo = {}
        this.selectSvg = {}
      }

      this.mouseDownEvent(e)
    },
    MousedownSvg(id, index, pointX, pointY, e, extend_attr) {
      if (
        this.$store.getters.CurrentlySelectedToolBar.CreateType == 'click' ||
        this.drawLine.length > 0
      ) {
        return
      }
      // this.$store.clearCurrentlySelectedToolBarAction();
      //框选和shift加减
      if (this.containArr.length > 0) {
        let haves = false
        let havesi = ''

        for (let i = 0; i < this.containArr.length; i++) {
          let item = this.containArr[i]
          if (item.id == id) {
            havesi = i
            haves = true
          }
        }
        this.selectBoxall.drag = haves
        if (haves) {
          if (e.shiftKey) {
            this.containArr.splice(havesi, 1)
          }
          return
        } else {
          if (e.shiftKey) {
            this.selectBoxall.drag = true
            let selectItem = this.svgLists[index]
            this.containArr.push(selectItem)
            return
          }
        }
      } else {
        if (e.shiftKey) {
          this.selectBoxall.drag = true
          if (this.$parent.selectSvgInfo && this.$parent.selectSvgInfo.id) {
            this.containArr.push(this.$parent.selectSvgInfo)
            this.deleteEit()
          }
          let selectItem = this.svgLists[index]
          this.containArr.push(selectItem)
          this.selectSvg = {}
          this.$parent.selectSvgInfo = {}
          this.$eventBus.$emit('drawChange', [this.$parent.svgLists, this.selectSvg])
          return
        } else {
          this.selectBoxall.drag = false
        }
      }

      this.containArr = []
      this.drageState = false
      let type = this.$store.getters.CurrentlySelectedToolBar.Type
      /*  if (type) {
        let haves = this.toolTypeArray.indexOf(type)
        if (haves != -1) {
          return
        }
      } */

      this.$store.commit('zt/clearCurrentlySelectedToolBarAction')

      //从数组里面根据index找到当前元素
      this.selectSvg.ID = id
      this.selectSvg.Index = index
      this.mouseStatus = 1
      this.selectSvg.mPositionX = e.offsetX
      this.selectSvg.mPositionY = e.offsetY
      this.selectSvg.pointX = pointX
      this.selectSvg.pointY = pointY
      this.$parent.selectSvgInfo = this.svgLists[index]
      this.clickType = 'draggable'
      //开启编辑状态
      this.initGroup()
      this.updataSelectB()
      this.$eventBus.$emit('drawChange', [this.svgLists, this.selectSvg])
    },
    getObjBbox(obj) {
      var bb = {}
      if (obj.extend_attr && obj.extend_attr.Locking && obj.extend_attr.Locking.val.selectval) {
        //已锁定的元素不能拖拽
        return bb
      }
      if (
        obj.type == 'pipeline_v' ||
        obj.type == 'pipeline_h' ||
        obj.type == 'lineSegment' ||
        obj.type == 'rectSegment'
      ) {
        bb = {
          x: obj.svgPositionX,
          y: obj.svgPositionY,
          width: obj.sizeW * (obj.mPoint.endX - obj.mPoint.startX),
          height: obj.sizeH * (obj.mPoint.endY - obj.mPoint.startY)
        }
      } else if (obj.type == 'pipeline') {
        let path = obj.extend_attr.pathS
        let svgx = obj.svgPositionX
        let svgy = obj.svgPositionY
        let minx = Number.MAX_VALUE
        let miny = Number.MAX_VALUE
        let maxx = Number.MIN_VALUE
        let maxy = Number.MIN_VALUE
        path.forEach(item => {
          let a = item.split(' ')
          if (minx > Number(a[0]) + svgx) minx = Number(a[0]) + svgx
          if (miny > Number(a[1]) + svgy) miny = Number(a[1]) + svgy
          if (maxx < Number(a[0]) + svgx) maxx = Number(a[0]) + svgx
          if (maxy < Number(a[1]) + svgy) maxy = Number(a[1]) + svgy
        })
        bb = {
          x: minx,
          y: miny,
          width: obj.sizeW * (maxx - minx),
          height: obj.sizeH * (maxy - miny)
        }
      } else {
        bb = {
          x: obj.svgPositionX,
          y: obj.svgPositionY,
          width: obj.sizeW * obj.extend_attr.width * 2,
          height: obj.sizeH * obj.extend_attr.height * 2
        }
      }
      var angleOld = obj.angle
      if (angleOld) {
        var angle = (angleOld * Math.PI) / 180.0
        var rminx = Number.MAX_VALUE,
          rminy = Number.MAX_VALUE,
          rmaxx = Number.MIN_VALUE,
          rmaxy = Number.MIN_VALUE
        var cx = Math.round(bb.x + bb.width / 2),
          cy = Math.round(bb.y + bb.height / 2)
        var pts = [
          [bb.x - cx, bb.y - cy],
          [bb.x + bb.width - cx, bb.y - cy],
          [bb.x + bb.width - cx, bb.y + bb.height - cy],
          [bb.x - cx, bb.y + bb.height - cy]
        ]
        var j = 4
        while (j--) {
          var x = pts[j][0],
            y = pts[j][1],
            r = Math.sqrt(x * x + y * y)
          var theta = Math.atan2(y, x) + angle
          x = Math.round(r * Math.cos(theta) + cx)
          y = Math.round(r * Math.sin(theta) + cy)

          // now set the bbox for the shape after it's been rotated
          if (x < rminx) rminx = x
          if (y < rminy) rminy = y
          if (x > rmaxx) rmaxx = x
          if (y > rmaxy) rmaxy = y
        }

        bb.x = rminx
        bb.y = rminy
        bb.width = rmaxx - rminx
        bb.height = rmaxy - rminy
      }
      return bb
    },
    bboxintersect(r1, r2) {
      if (!r1 || !r2) return false
      return (
        r2.x < r1.x + r1.width &&
        r2.x + r2.width > r1.x &&
        r2.y < r1.y + r1.height &&
        r2.y + r2.height > r1.y
      )
    },
    MouseupCanvas() {
      let containArr = []
      let doc = document.getElementById('selectBoxall')
      let b1 = doc.getBBox()
      if (b1.width || b1.height) {
        this.svgLists.forEach(item => {
          let b2 = this.getObjBbox(item)
          if (this.bboxintersect(b1, b2)) {
            containArr.push(item)
          }
        })
        this.containArr = containArr
      }
      this.selectBoxall = {
        state: false,
        drag: false,
        x: 0,
        y: 0,
        w: 0,
        h: 0
      }

      if (this.infoChange) {
        this.changeList()
      }
      this.mouseUpstate = true

      this.mouseUpEvent()
      this.$refs.guidex_dom.style.display = 'none'
      this.$refs.guidey_dom.style.display = 'none'
      this.$store.commit('zt/clearCurrentlySelectedToolBarAction')

      if (this.mouseStatus == 0) {
        return
      }

      this.mouseStatus = 0
      // this.clickType = '';
      if (this.clickType == 'draggable' || this.clickType == 'click') {
        this.clickType = ''
      }

      setTimeout(() => {
        this.updataSelectB()
      }, 250)
      this.$parent.showRightToolBar()
    },
    initSvg() {
      let _this = this
      this.$nextTick(() => {
        let canvasdiv = _this.$refs.canvas

        document.addEventListener(
          'keydown',
          function(event) {
            if (event.ctrlKey === true) {
              event.preventDefault()
            }
          },
          false
        )
        document.addEventListener(
          'mousewheel',
          function(e) {
            _this.mousewheel(e)
          },
          { passive: false }
        )

        canvasdiv.addEventListener(
          'dragover',
          function(e) {
            e.preventDefault()
          },
          false
        )
        canvasdiv.addEventListener(
          'drop',
          function(e) {
            e.preventDefault()

            if (
              _this.$store.getters.CurrentlySelectedToolBar.Type == '' ||
              _this.$store.getters.CurrentlySelectedToolBar.CreateType != 'draggable'
            ) {
              return
            }
            /* 
            // 这里是单独拖拽采集点到组或者撬（我是这么理解的），后来开会说单独拖拽采集点，和原来关联采集点一样，
            也就是说单独拖拽采集点，直接显示采集点名称、采集点值，这段代码就注释了，万一以后又改了呢
            let obj = e.srcElement ? e.srcElement : eveent.target
            let currentlySelected = _this.$store.getters.CurrentlySelectedToolBar
            // 拖拽采集点到画布 
            if (currentlySelected.Type == 'svg_coPoint') {
              // 单独拖拽采集点不能绘制，需要在组、撬里面
              if (!obj.id || obj.id.indexOf('svg_') == -1) {
                _this.$message.warning('采集点需要放到到组或撬上')
                return
              }
              let coInfo = currentlySelected.extend_attr.coInfo //采集点信息
              let svgID = _this.parentMoudleID(obj) //获取画布上拖拽组件ID

              _this.svgLists.forEach(el => {
                if (el.id == svgID) {
                  let width1 = _this.$parent.getActualWidthOfChars(
                    coInfo.pointName,
                    el.extend_attr.f_content_size.val,
                    400
                  )
                  let width2 = _this.$parent.getActualWidthOfChars(
                    coInfo.currVal,
                    el.extend_attr.f_content_size.val,
                    700
                  )
                  let width3 = _this.$parent.getActualWidthOfChars(
                    coInfo.dataUnit,
                    el.extend_attr.f_content_size.val,
                    400
                  )
                  if (width1 > el.extend_attr.f_content_width1) {
                    el.extend_attr.f_content_width1 = width1
                  }
                  if (width2 > el.extend_attr.f_content_width2) {
                    el.extend_attr.f_content_width2 = width2
                  }
                  if (width3 > el.extend_attr.f_content_width3) {
                    el.extend_attr.f_content_width3 = width3
                  }

                  // 重新计算边界
                  let rect2Width =
                    el.extend_attr.f_content_width1 +
                    el.extend_attr.f_content_width2 +
                    el.extend_attr.f_content_width3 +
                    97 //采集点矩形框宽度
                  let rect1Height =
                    _this.$parent.getActualHeightOfChars(
                      coInfo.pointName,
                      el.extend_attr.f_title_size.val
                    ) + 16 //标题矩形框高度
                  let rect2Height =
                    (_this.$parent.getActualHeightOfChars(
                      coInfo.pointName,
                      el.extend_attr.f_content_size.val
                    ) +
                      10) *
                      (el.extend_attr.f_list.length + 1) +
                    20 //采集点矩形框高度
                  let height = (rect1Height + rect2Height + 10) / 2 //这里的10是两个矩形框间距
                  let width = rect2Width / 2
                  el.extend_attr.width = width
                  el.extend_attr.height = height
                  el.extend_attr.f_list.push(coInfo)
                }
              })
              // 清空选中状态
              _this.clearSelectSvg()
              return
            } else if (currentlySelected.Type == 'svg_eq') {
              // 单独拖拽采集点不能绘制，需要在组、撬里面
              if (
                currentlySelected.extend_attr.coList &&
                currentlySelected.extend_attr.coList.length == 0
              ) {
                _this.$message.warning('该设备没有绑定采集点')
                return
              }
              if (!obj.id || obj.id.indexOf('svg_') == -1) {
                _this.$message.warning('设备需要放到到组或撬上')
                return
              }
              let coList = currentlySelected.extend_attr.coList //采集点信息
              let svgID = _this.parentMoudleID(obj) //获取画布上拖拽组件ID

              _this.svgLists.forEach(el => {
                if (el.id == svgID) {
                  let width1Arry = []
                  let width2Arry = []
                  let width3Arry = []
                  coList.forEach(coInfo => {
                    width1Arry.push(
                      _this.$parent.getActualWidthOfChars(
                        coInfo.pointName,
                        el.extend_attr.f_content_size.val,
                        400
                      )
                    )
                    width2Arry.push(
                      _this.$parent.getActualWidthOfChars(
                        coInfo.currVal,
                        el.extend_attr.f_content_size.val,
                        700
                      )
                    )
                    width3Arry.push(
                      _this.$parent.getActualWidthOfChars(
                        coInfo.dataUnit,
                        el.extend_attr.f_content_size.val,
                        400
                      )
                    )
                  })
                  let width1 = Math.max(...width1Arry)
                  let width2 = Math.max(...width2Arry)
                  let width3 = Math.max(...width3Arry)

                  if (width1 > el.extend_attr.f_content_width1) {
                    el.extend_attr.f_content_width1 = width1
                  }
                  if (width2 > el.extend_attr.f_content_width2) {
                    el.extend_attr.f_content_width2 = width2
                  }
                  if (width3 > el.extend_attr.f_content_width3) {
                    el.extend_attr.f_content_width3 = width3
                  }

                  // 重新计算边界
                  let text = coList[0].pointName
                  let rect2Width =
                    el.extend_attr.f_content_width1 +
                    el.extend_attr.f_content_width2 +
                    el.extend_attr.f_content_width3 +
                    97 //采集点矩形框宽度
                  let rect1Height =
                    _this.$parent.getActualHeightOfChars(text, el.extend_attr.f_title_size.val) + 16 //标题矩形框高度
                  let rect2Height =
                    (_this.$parent.getActualHeightOfChars(text, el.extend_attr.f_content_size.val) +
                      10) *
                      (el.extend_attr.f_list.length + coList.length) +
                    20 //采集点矩形框高度
                  let height = (rect1Height + rect2Height + 10) / 2 //这里的10是两个矩形框间距
                  let width = rect2Width / 2
                  el.extend_attr.width = width
                  el.extend_attr.height = height
                  el.extend_attr.f_list.push(...coList)
                }
              })

              // 清空选中状态
              _this.clearSelectSvg()
              return
            } */
            let eChartsOption = _this.$store.getters.CurrentlySelectedToolBar.EChartsOption
            //根据类型和鼠标位置创建组件

            let svg_id = GenUUidByType(
              _this.$store.getters.CurrentlySelectedToolBar.Identity,
              8,
              16
            )

            let extend_attr = _this.$store.getters.CurrentlySelectedToolBar.extend_attr
            let w = extend_attr.width || 0
            let h = extend_attr.height || 0
            let svgItem = {
              id: svg_id,
              title: _this.$store.getters.CurrentlySelectedToolBar.Title,
              type: _this.$store.getters.CurrentlySelectedToolBar.Type,
              typeName: _this.$store.getters.CurrentlySelectedToolBar.TypeName,
              //svgColor: _this.$store.getters.CurrentlySelectedToolBar.Color,
              extend_attr: extend_attr,
              svgPositionX: (e.offsetX - w) / _this.stationObj.zoom,
              svgPositionY: (e.offsetY - h) / _this.stationObj.zoom,
              echartsOption: eChartsOption ? JSON.parse(eChartsOption) : '',
              // size:1,
              sizeW: 1,
              sizeH: 1,
              angle: 0
              //translate:`translate(${this.mousePosition.positionX},${this.mousePosition.positionY})`
            }
            _this.history.alone = false
            _this.svgLists.push(svgItem)
            _this.$parent.selectSvgInfo = svgItem
            if (_this.$parent.selectSvgInfo.extend_attr.color_id) {
              //渐变颜色id
              _this.$parent.selectSvgInfo.extend_attr.color_id.val = 'cid' + svg_id
            }
            _this.$parent.box.right = 0
            // 组、撬的时候显示采集点设置
            if (svgItem.id.substr(0, 2) == '99') {
              _this.$parent.radio = 1
              _this.$parent.pointChecked = true
              _this.$parent.isGroupCollect = true
            } else {
              _this.$parent.isGroupCollect = false
            }

            _this.selectSvg.ID = svg_id
            _this.selectSvg.Index = _this.svgLists.length - 1
            _this.clickType = ''
            _this.drageState = false
            _this.$nextTick(() => {
              _this.$eventBus.$emit('drawChange', [_this.svgLists, _this.selectSvg])
              _this.initGroup()
              _this.updataSelectB()
            })
          },
          false
        )
      })
    },
    // 新建/打开
    openOrbulid(data, stationId) {
      let that = this
      // 清除选中
      this.selectSvg = {}
      if (this.$parent.selectSvgInfo && this.$parent.selectSvgInfo.id) {
        this.deleteEit()
      }
      this.$parent.selectSvgInfo = {}
      // 清除数据
      if (data) {
        // data.configureInfo
        let getListTabele = [
          'totalTiredness',
          'conditioning',
          'pressureCollection',
          'concentrationCollection',
          'temperatureCollection',
          'totalTiredConditions',
          'instantaneousService'
        ]
        let info = JSON.parse(data)
        // 更新采集点
        let svgLists = info.svgLists || []
        let pointIdArr = []
        for (let i = 0; i < svgLists.length; i++) {
          if (svgLists[i].extend_attr.v_eqid && svgLists[i].extend_attr.v_eqid.val) {
            let haves = getListTabele.indexOf(svgLists[i].type)
            if (haves !== -1) {
              pointIdArr.push({ key: i, id: svgLists[i].extend_attr.v_eqid.val })
            }
          }
        }
        if (pointIdArr.length) {
          let arr = []
          pointIdArr.forEach(item => {
            arr.push(item.id)
          })
          scadaApi.findPointOfStation({ stationId, pointIds: arr.toString() }).then(res => {
            if (res.code == 200) {
              let idArr = res.data
              for (let i = 0; i < idArr.length; i++) {
                let val = ''
                if (
                  info.svgLists[pointIdArr[i].key].title == '总累标况' ||
                  info.svgLists[pointIdArr[i].key].title == '总累工况'
                ) {
                  val = idArr[i].totalVal + idArr[i].dataUnit
                } else {
                  val = idArr[i].currVal + idArr[i].dataUnit
                }
                info.svgLists[pointIdArr[i].key].extend_attr.f_eqvalue.val = val
                // info.svgLists[pointIdArr[i].key].extend_attr.v_eqname.val = idArr[i].pointName
              }
              that.svgLists = info.svgLists
            } else {
              that.svgLists = info.svgLists
            }
          })
        } else {
          this.svgLists = info.svgLists || []
        }
        this.svgLists = info.svgLists || []
        this.stationObj.zoom = info.zoom || 1
        this.$parent.box.width = info.width || 1600
        this.$parent.box.height = info.height || 760
        this.$parent.box.background = info.background || 'fff'
        this.stationObj.legendx = info.legendx || 10
        this.stationObj.legendy = info.legendy || 10

        // 底部是否显示采集点
        let pointChecked = true
        if (info.pointChecked === false) {
          pointChecked = false
        }
        this.$parent.radio = info.clickPointChecked || 1
        this.$parent.handleCheckedCitiesChange(pointChecked)

        this.zoomChange(this.stationObj.zoom)
        // 自定义颜色设置为空
        this.$parent.$refs.topToolBar.bgColor = info.other || ''
        // 设置主题
        if (info.background == '0b1524') {
          document.body.classList.add('black')
          that.$parent.$refs.topToolBar.selectColor = 'black'
          that.showBgColor = '#0b1524'
        } else {
          document.body.classList.remove('black')
          that.$parent.$refs.topToolBar.selectColor = 'white'
          that.showBgColor = '#fff'
        }
      } else {
        this.svgLists = []
        this.$parent.$refs.topToolBar.bgColor = ''
        this.$parent.radio = 1
        this.$parent.handleCheckedCitiesChange(true)
      }

      // 打开或新建的时候，左侧组、撬是接口获取的，需要刷新
      // this.$parent.$refs.leftToolBar.changeToolTab('module')

      this.history = {
        alone: true,
        allow: true,
        index: 0,
        list: []
      }
    },
    clearSelectSvg() {
      this.clickType = '' //这个要设置成空，否则会走MouseMove方法报错
      this.$parent.selectSvgInfo = {}
      this.selectSvg = {}
      this.deleteEit()
      this.$eventBus.$emit('drawChange', [this.svgLists, this.selectSvg])
    },
    // 获取点击要素的ID，如果不是组或者撬，递归父级节点
    parentMoudleID(obj) {
      if (obj.id.substr(0, 2) != '99') {
        return this.parentMoudleID(obj.parentNode)
      } else {
        return obj.id
      }
    }
  }
}
</script>
<style scoped lang="scss">
.Svglegend {
  position: absolute;
  top: 30px;
  left: 30px;
  background: #eee;
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
}
#guide-x {
  display: none;
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
}

#guide-y {
  display: none;
  position: absolute;
  height: 100%;
  left: 0px;
  top: 0px;
}
.contextMenu {
  position: absolute;
  z-index: 99999;
  background: #ffffff;
  padding: 5px 0;
  margin: 0px;
  display: block;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.contextMenu li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.contextMenu .shortcut {
  width: 115px;
  text-align: right;
  float: right;
}

.contextMenu p {
  text-decoration: none;
  display: block;
  padding: 5px 10px;
  margin: 0;
  user-select: none;
  -webkit-user-select: none;
}

.contextMenu p:hover {
  background-color: #0b72b5;
  color: #ffffff;
  cursor: default;
}

.contextMenu .disabled {
  color: #999;
}
.contextMenu .disabled:hover {
  color: #999;
  background-color: transparent;
}
.contextMenu li.separator {
  border-top: solid 1px #e3e3e3;
  padding-top: 5px;
  margin-top: 5px;
}
.topo-layer-view-selected {
  outline: 1px solid #0cf;
  filter: drop-shadow(0px 0px 6px rgba(25, 144, 255, 1));
  -webkit-filter: drop-shadow(0px 0px 6px #1990ff);
}
</style>
